@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
    height: 100%;
}

.react-calendar__navigation {
    display: grid;
    grid-template-columns: min-content min-content 1fr min-content min-content;
    margin-bottom: 5px;
}

.react-calendar__navigation__arrow {
    border-radius: 6px;
    padding: 6px;
}

.react-calendar__navigation__label__labelText {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 2px 10px;
    border-radius: 6px;
}

.react-calendar button[disabled] {
    opacity: 0.25;
}

.react-calendar__month-view__weekdays {
    opacity: 0.5;
    text-align: center;
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.react-calendar__tile {
    height: 32px;
    font-size: 1.15rem;
    border-radius: 16px;
    margin: 4px 0;
}

.react-calendar__tile--active {
    color: white;
    background-color: #56ba8e;
}

.react-calendar__navigation__arrow:hover,
.react-calendar__navigation__label__labelText:hover,
.react-calendar__tile:hover {
    background-color: #00000010;
}

.react-calendar__tile--active:hover {
    background-color: #56ba8e;
}
